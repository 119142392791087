<template>
  <ReportForm :class="{ flat: isFlat }" :header="header" :allowToggle="false">
    <div class="p-formgrid p-grid p-fluid">
      <ReadOnlyField class="p-col-12" name="Task Duration" :value="duration" />
    </div>
    <div v-if="summary.fields?.length" class="p-formgrid p-grid p-fluid">
      <ReadOnlyField
        class="p-col-12"
        v-for="field in fields"
        :key="field.fieldId"
        :name="field.name"
        :value="field.value"
        :dynamic="field.dynamic"
        :basic="field.basic"
      />
    </div>
  </ReportForm>
</template>

<script>
import { computed } from 'vue'

import ReportForm from '@/components/custom/ReportForm.vue'
import ReadOnlyField from './ReadOnlyField.vue'
import { prettifyDuration } from '../../utils/utilities'
import { FIELD_TYPES } from '../../definitions'

export default {
  name: 'KPIFormSummary',
  components: { ReadOnlyField, ReportForm },
  props: { isFlat: { type: Boolean, default: false }, summary: { type: Object, required: true } },
  setup(props) {
    const duration = computed(() => prettifyDuration(props.summary.duration))
    const fields = computed(() =>
      props.summary?.fields?.map((field) => ({
        name: field.label,
        value: field.value,
        basic: field.type !== FIELD_TYPES.TEXT_AREA.code
      }))
    )
    const header = computed(() => {
      const _header = { title: props.summary.taskName, tag: null }
      const { channel } = props.summary
      if (typeof channel !== 'string' || channel?.length < 1) {
        _header._tag = {
          value: 'No slack channel',
          severity: 'danger'
        }
      } else {
        _header._tag = {
          value: channel,
          severity: 'info'
        }
      }
      return _header
    })
    return { duration, fields, header }
  }
}
</script>

<style>
</style>
