<template>
  <ReportForm
    :class="{ flat: isFlat }"
    :header="{ title: 'EOD Report', slackUrl: summary.slackUrl }"
    :allowToggle="false"
  >
    <div class="p-formgrid p-grid p-fluid">
      <div class="p-col-12 p-mb-4" style="gap: 0.5rem">
        <label class="" for="mood">How did you feel about today?</label>
        <div v-html="smiley" style="font-size: 2rem"></div>
      </div>
      <ReadOnlyField
        class="p-col-12 p-mb-3"
        name="Do you need help from your client, coworkers or SM to make tomorrow a productive day?*"
        :value="summary.instructions"
      />
      <ReadOnlyField class="p-col-12 4 p-mb-3" name="Today Overview" :value="summary.todayOverview" :basic="false" />
      <ReadOnlyField
        v-if="summary.mostImportant"
        class="p-col-12 4 p-mb-3"
        name="What was most important today? Any accomplishments or escalations worth noting?"
        :value="summary.mostImportant"
        :basic="false"
      />
      <ReadOnlyField class="p-col-12 4 p-mb-3" name="Next Shift" :value="summary.nextShift" :basic="false" />
    </div>
  </ReportForm>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import ReadOnlyField from './ReadOnlyField.vue'
import ReportForm from '@/components/custom/ReportForm.vue'

export default {
  name: 'EODOverviewFormSummary',
  components: { ReadOnlyField, ReportForm },
  props: {
    summary: { type: Object, required: true },
    isFlat: { type: Boolean, default: false }
  },
  setup(props) {
    const store = useStore()
    const smiley = computed(() => store.getters.getSmileys([props.summary.howsToday - 1]))
    return { smiley }
  }
}
</script>

<style>
</style>
